<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <base-material-card color="drawerColorSecondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Filtros para la Tabla</div>
          </template>
          <!-- Filtrado por Material -->
          <v-combobox
            v-model="materialNuevo"
            :items="this.materiales"
            item-text="nombre"
            item-value="idMaterial"
            label="Material"
            return-object
            auto-select-first
          />
          <!-- Filtrado por Descripcion  -->
          <v-text-field
            v-model="descripcionFiltroValor"
            label="Descripcion"
            append-icon="mdi-magnify"
          />
          <!-- Filtrado por Estado  -->
          <v-select v-model="row" :items="estado" hide-details label="Estado"></v-select>
          <!-- Diseño de las fechas -->
          <v-row>
            <v-col cols="6" md="6">
              <v-menu
                ref="menuFinal"
                v-model="menuInicial"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs}">
                  <v-text-field
                    v-model="dateInicioFormateada"
                    label="Fecha Inicio"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <!-- Picker de las fechas de inicio -->
                <v-date-picker
                  v-model="dateInicio"
                  no-title
                  @input="menuInicial = false"
                  class="pa-0 ma-0"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Diseño de las fechas -->
            <v-col cols="6" md="6">
              <v-menu
                ref="menuFinal"
                v-model="menuFinal"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs}">
                  <v-text-field
                    v-model="dateFinalFormateada"
                    label="Fecha Final"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFinal"
                  no-title
                  class="pa-0 ma-0"
                  @input="menuFinal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="cantidadPrestados"
                disabled
                filled
                label="No. Préstamos Prestados"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="cantidadDevueltos"
                disabled
                filled
                label="No. Préstamos Devueltos"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="8" lg="8">
        <v-responsive class="overflow-y-auto" max-height="calc(90vh - 350px)">
          <v-data-table
            :headers="headers"
            :items="prestamos"
            class="elevation-2 category-table"
            :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus'
                  }"
            dense
            mobile-breakpoint
            no-data-text="No se Encuentran Préstamos"
          />
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ConsultarMisPrestamos",
  data() {
    return {
      cantidadPrestados: 0,
      cantidadDevueltos: 0,
      menuInicial: false,
      menuFinal: false,
      row: "Todos",
      dateInicio: "",
      dateFinal: "",
      dateInicioFormateada: "",
      dateFinalFormateada: "",
      estado: ["Todos", "Prestado", "Devuelto"],
      materialNuevo: null,
      materialFiltroValor: "",
      estadoFiltroValor: "",
      matriculaFiltroValor: "",
      descripcionFiltroValor: "",
      tutorFiltroValor: "",
      filtrarPrestamos: "",
      dialogMaterial: false,
      prestamos: [],
      materiales: [],
    };
  },
  mounted() {
    /**
     * Aqui hacemos la peticion de los materiales para ser utilizados
     * Se almacenan todos los materiales en un array.
     */
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/materiales", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((materiales) => {
        materiales.unshift("NINGUNO");
        this.materiales = materiales;
      });

    /**
     *
     */
    fetch(
      process.env.VUE_APP_URL_BACKEND +
        "/administrador/prestamos/cuenta/" +
        this.$store.state.cuenta.idCuenta,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.cuenta.token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((prestamos) => {
        prestamos.forEach((prestamo) => {
          if (prestamo.estado == "PRESTADO") this.cantidadPrestados++;
          else this.cantidadDevueltos++;

          let newDate = this.$cambiarFormatoDate(prestamo.fechaPedido);
          prestamo.fechaPedido = newDate;
          if (prestamo.fechaDevuelto !== null) {
            newDate = this.$cambiarFormatoDate(prestamo.fechaDevuelto);
            prestamo.fechaDevuelto = newDate;
          } else {
            prestamo.fechaDevuelto = "PRESTADO";
          }
        });
        this.prestamos = prestamos;
      });
  },
  computed: {
    headers() {
      return [
        {
          text: "Material",
          value: "material",
          aling: "left",
          with: "5%",
          filter: this.materialFiltro,
        },
        { text: "Desc.", value: "descripcion", filter: this.descripcionFiltro },
        { text: "Prefijo", value: "potenciador" },
        {
          text: "Fecha Pedido",
          value: "fechaPedido",
          filter: this.fechaFiltro,
        },
        {
          text: "Fecha Devuelto",
          value: "fechaDevuelto",
          filter: this.tipoPrestamosFiltro,
        },
      ];
    },
  },
  watch: {
    dateInicio() {
      this.dateInicioFormateada = this.formatDate(this.dateInicio);
      //console.log(this.dateInicioFormateada);
    },
    dateFinal() {
      this.dateFinalFormateada = this.formatDate(this.dateFinal);
      //console.log(this.dateFinalFormateada);
    },
  },
  methods: {
    //Metodo para dar el formato a la fecha
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    /**
     * Filtro para el el tipo de material, si esta prestado o devuelto o ambos.
     * @param value es el valor que sera testeado
     * @returns {boolean}
     */
    tipoPrestamosFiltro(value) {
      let tipoPrestamo = this.row;

      if (tipoPrestamo == "Todos") {
        this.filtrarPrestamos = "";
      } else if (tipoPrestamo == "Prestado") {
        this.filtrarPrestamos = "PRESTADO";
      } else if (tipoPrestamo == "Devuelto") {
        this.filtrarPrestamos = "PRESTADO";
        return !value
          .toLowerCase()
          .includes(this.filtrarPrestamos.toLowerCase());
      }
      return value.toLowerCase().includes(this.filtrarPrestamos.toLowerCase());
    },
    /**
     * Filtro para el material
     * @param value es el valor que sera testeado
     * @returns {boolean}
     */
    materialFiltro(value) {
      //console.log("Material filtro");
      //console.log(value);
      // Primero checamos que tenga algo para filtrar y si no lo sacamos de la funcion
      if (
        this.materialNuevo == null ||
        this.materialNuevo == "NINGUNO" ||
        this.materialNuevo == ""
      ) {
        this.materialFiltroValor = "";
      } else {
        this.materialFiltroValor = this.materialNuevo.nombre;
      }
      //Checamos el loop para verificar el valor que estamos buscando
      return value
        .toLowerCase()
        .includes(this.materialFiltroValor.toLowerCase());
    },
    descripcionFiltro(value) {
      if (!this.descripcionFiltroValor) {
        return true;
      }
      return value
        .toString()
        .toLowerCase()
        .includes(this.descripcionFiltroValor.toString().toLowerCase());
    },

    fechaFiltro(value) {
      if (this.dateInicioFormateada != "" && this.dateFinalFormateada != "") {
        if (
          Date.parse(value) >= Date.parse(this.dateInicioFormateada) &&
          Date.parse(value) <= Date.parse(this.dateFinalFormateada)
        ) {
          return true;
        }
      } else {
        return true;
      }
    },
  },
};
</script>


<style scoped>
.margenFilter {
  margin-left: 10px;
  margin-right: 10px;
}
</style>